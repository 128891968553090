import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.scss";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home">
      <div className="home__details">
        <h1>AMMO GUIDE</h1>
        <p>Select a type to get started with your build.</p>
      </div>
      <div className="home__types">
        <div
          onClick={() => navigate("/browse-components/Handgun Ammunition")}
          className="home__type"
        >
          Handgun
        </div>
        <div
          onClick={() => navigate("/browse-components/Shotgun")}
          className="home__type"
        >
          Shotgun
        </div>
        <div
          onClick={() => navigate("/browse-components/Rifle Ammunition")}
          className="home__type"
        >
          Rifle
        </div>
        <div
          onClick={() => navigate("/browse-components/Rimfire Ammmunition")}
          className="home__type"
        >
          Rimfire
        </div>
      </div>
    </div>
  );
};

export default Home;
