import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import BrowseComponents from "./pages/BrowseComponents/BrowseComponents";

import "./App.scss";
import Product from "./pages/Product/Product";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/browse-components/:ammo"
          element={<BrowseComponents />}
        ></Route>
        <Route
          path="/product/:slug"
          element={<Product />}
        ></Route>
      </Routes>
    </div>
  );
};

export default App;
