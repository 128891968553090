import axios from "axios";

const getJWTToken = async () => {
  try {
    if (!localStorage.getItem("token")) {
      return;
    }
    let t = JSON.parse(localStorage.getItem("token")).access;

    return t;
  } catch (err) {
    try {
      console.log(err);
      let t = JSON.parse(localStorage.getItem("token")).refresh;
      if (err.response.status === 401) {
        const res = await axios.post(
          `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/token/refresh`,
          {
            refresh: t,
          }
        );
        localStorage.setItem("token", {
          access: res.data.access,
          refresh: t,
        });
      }
    } catch (err) {
      if (err.response.status === 401) {
        localStorage.removeItem("token");
      }
      return;
    }
  }
};

export const getData = async () => {
  const token = await getJWTToken();
  console.log(token);
  console.log(token);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.get(
    `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/users/me`,
    config
  );
  console.log(res);
  return res.data;
};
