import React, { useState } from "react";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./Header.scss";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { useEffect } from "react";
import { getData } from "../../utils/getData";

const Header = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "rgb(40, 40, 40)",
    border: "none",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };

  const handleClick = (newState) => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openLogin, setOpenLogin] = React.useState(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => {
    setLoginPassword("");
    setLoginUsername("");
    setOpenLogin(false);
  };

  const [openRegister, setOpenRegister] = React.useState(false);
  const handleOpenRegister = () => setOpenRegister(true);
  const handleCloseRegister = () => {
    setRegisterEmail("");
    setRegisterPassword("");
    setRegisterUsername("");
    setOpenRegister(false);
  };
  const token = localStorage.getItem("token");

  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [data, setData] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/users/login",
        {
          username: loginUsername,
          password: loginPassword,
        }
      );
      console.log(res);
      localStorage.setItem("token", JSON.stringify(res.data.token));
      handleClick({
        vertical: "bottom",
        horizontal: "left",
        type: "success",
        message: "Login Successfully!",
      });

      handleCloseLogin();
    } catch (err) {
      handleClick({
        vertical: "bottom",
        horizontal: "left",
        type: "error",
        message:
          "User not found! Make sure the username and password is correct.",
      });
    }
  };

  const handleRegister = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post(
        "https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/users/register",
        {
          username: registerUsername,
          email: registerEmail,
          first_name: registerUsername,
          last_name: registerUsername,
          password: registerPassword,
          role: "USER",
        }
      );
      console.log(res);

      handleClick({
        vertical: "bottom",
        horizontal: "left",
        type: "success",
        message: "Your account is created!",
      });

      handleCloseRegister();
    } catch (err) {
      handleClick({
        vertical: "bottom",
        horizontal: "left",
        type: "error",
        message: err.message,
      });
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      const d = await getData();
      setData(d);
    };
    if (localStorage.getItem("token")) {
      getProfile();
    }
  }, [token]);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    type: "",
  });
  const { vertical, horizontal, open, message, type } = state;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openLogin}
          onClose={handleCloseLogin}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openLogin}>
            <Box className="modal" sx={style} style={{ padding: "24px 36px" }}>
              <div className="modal__title">Login</div>
              <form onSubmit={handleLogin}>
                <input
                  placeholder="Enter your username"
                  type="text"
                  required
                  value={loginUsername}
                  onChange={(e) => setLoginUsername(e.target.value)}
                />
                <input
                  placeholder="Enter your password"
                  required
                  type={"password"}
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                <button type="submit">Login</button>
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openRegister}
          onClose={handleCloseRegister}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openRegister}>
            <Box className="modal" sx={style} style={{ padding: "24px 36px" }}>
              <div className="modal__title">Register</div>
              <form onSubmit={handleRegister}>
                <input
                  placeholder="Enter your username"
                  type="text"
                  value={registerUsername}
                  required
                  onChange={(e) => setRegisterUsername(e.target.value)}
                />
                <input
                  placeholder="Enter your email"
                  required
                  type="email"
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
                <input
                  placeholder="Enter your password"
                  required
                  type={"password"}
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
                <button type="submit">Register</button>
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>
      <div className="header">
        <ul>
          <Link to={"/"} className="header__logo">
            Ammo Guide
          </Link>
          <div className="header__links"></div>
          {!data && (
            <>
              <button onClick={handleOpenLogin} className="header__login">
                Login
              </button>
              <button
                onClick={handleOpenRegister}
                // onClick={() =>
                //   handleClick({
                //     vertical: "bottom",
                //     horizontal: "left",
                //     message:
                //       "User not found! Make sure the username and password is correct.",
                //   })
                // }
                style={{ marginLeft: "18px" }}
                className="header__login"
              >
                Register
              </button>
            </>
          )}

          {data && <p>@{data.username}</p>}

          {data && (
            <button
              onClick={() => {
                localStorage.removeItem("token");
                setData("");
              }}
              className="header__login logout"
            >
              Logout
            </button>
          )}
        </ul>
      </div>
    </>
  );
};

export default Header;
