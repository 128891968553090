import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";

import "./Product.scss";

const Product = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [vendors, setVendors] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (slug) {
      const getData = async () => {
        const res = await axios.get(
          `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/?slug=${slug}`
        );
        setData(...res.data.results);
        const response = await axios.get(
          `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/${slug}`
        );
        setVendors(response.data.results);
      };
      getData();
    }
  }, [slug]);

  return (
    data &&
    vendors && (
      <div className="product">
        <div className="product__title">
          {data.name}
          <span>-{data.brand}</span>
        </div>
        {width >= 600 && (
          <div className="product__container">
            <div className="product__container-title">Price</div>
            <div className="product__content-title">
              <span>Vendor</span>
              <span>Base</span>
              <span className="promo">Promo</span>
              <span>Shipping</span>
              <span>Availability</span>
              <span>Total</span>
              <span> &nbsp;</span>
            </div>
            <div className="product__content-container">
              {vendors.map((e, idx) => (
                <div
                  className={`product__content-box ${idx % 2 !== 0 && "odd"}`}
                >
                  <span>{e.vendor ? e.vendor : "-"}</span>
                  <span>{e.base_price ? `$${e.base_price}` : "-"}</span>
                  <span className="promo" style={{ fontSize: "1.3rem" }}>
                    {e.promo ? e.promo : "-"}
                  </span>
                  <span>{e.shipping ? e.shipping : "Free shipping"}</span>
                  <span>{e.stock ? "In stock" : "Out of stock"}</span>
                  <span>
                    {e.shipping
                      ? `$${e.shipping + e.base_price}`
                      : `$${e.base_price}`}
                  </span>
                  <span>
                    <a href={e.aff_url}>Buy</a>
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {width < 600 && (
          <div className="product__container">
            <div className="product__container-title">Price</div>{" "}
            {vendors.map((e, idx) => (
              <div className="browseComponents__product-container">
                <section>
                  <span style={{ marginRight: "auto" }}>
                    {e.vendor ? e.vendor : "-"}
                  </span>
                  <span>
                    {e.shipping
                      ? `$${e.shipping + e.base_price}`
                      : `$${e.base_price}`}
                  </span>
                  <span>
                    <a href={e.aff_url}>Buy</a>
                  </span>
                </section>
                <div>
                  <p>
                    Base price <span>{e.base_price ? e.base_price : "-"}</span>
                  </p>
                  <p>
                    Promo <span>{e.promo ? e.promo : "-"}</span>
                  </p>
                  <p>
                    Shipping <span>{e.shipping ? e.shipping : "-"}</span>
                  </p>
                </div>
                <div>
                  <p>
                    Stock <span>{e.stock ? "In stock" : "Out of stock"}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};

export default Product;
