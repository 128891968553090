import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./BrowseComponents.scss";
import { useRef } from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";

const BrowseComponents = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { ammo } = useParams();
  const [ammoType, setAmmoType] = useState(ammo);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    caliber__name: null,
    grain: null,
    shot_size: null,
    shell_length__length: null,
    casings: null,
    conditions: null,
  });
  const page = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState({
    caliber__type__iexact: ammoType,
    caliber__name__iexact: null,
    brand__iexact: null,
    condition__name__iexact: null,
    casing__type__iexact: null,
    shell_length__length__iexact: "",
    grain: "",
  });

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleAmmoChange = (e) => {
    setActivePage(1);
    setFilters({
      caliber__name: null,
      grain: null,
      shot_size: null,
      shell_length__length: null,
      brand: null,
    });
    setAmmoType(e.target.value);
    setSelectedFilters({
      caliber__type__iexact: e.target.value,
      caliber__name__iexact: "",
      brand__iexact: "",
      condition__name__iexact: "",
      casing__type__iexact: "",
      shell_length__length__iexact: "",
      grain: "",
    });
  };

  useEffect(() => {
    console.log(page);
  }, [page]);

  const handleSubmit = () => {
    const getFilterData = async () => {
      const res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/?caliber__type__iexact=${
          selectedFilters.caliber__type__iexact
            ? selectedFilters.caliber__type__iexact
            : ""
        }&caliber__name__iexact=${
          selectedFilters.caliber__name__iexact
            ? selectedFilters.caliber__name__iexact
            : ""
        }&brand__iexact=${
          selectedFilters?.brand__iexact ? selectedFilters?.brand__iexact : ""
        }
        &condition__name__iexact=${
          selectedFilters?.condition__name__iexact
            ? selectedFilters?.condition__name__iexact
            : ""
        }
        &casing__type__iexact=${
          selectedFilters?.casing__type__iexact
            ? selectedFilters?.casing__type__iexact
            : ""
        }
        &shell_length__length__iexact=${
          selectedFilters?.shell_length__length__iexact
            ? selectedFilters?.shell_length__length__iexact
            : ""
        }
        &grain=${selectedFilters?.grain ? selectedFilters?.grain : ""}
        `
      );
      setTotalCount(res.data.count);
      setData(res.data.results);
      console.log(selectedFilters);
      console.log(res);
    };
    getFilterData();
  };

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/?caliber__type__iexact=${ammoType}&page=${activePage}`
      );
      setTotalCount(res.data.count);
      setData(res.data.results);
    };

    const getFilter = async () => {
      let res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/attribute-filter?attribute=caliber__name&caliber__type=${ammoType}`
      );
      filters.caliber__name = res.data;
      res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/attribute-filter?attribute=grain&caliber__type=${ammoType}`
      );
      filters.grain = res.data;
      res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/attribute-filter?attribute=brand&caliber__type=${ammoType}`
      );
      filters.brand = res.data;
      res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/attribute-filter?attribute=shot_size&caliber__type=${ammoType}`
      );
      filters.shot_size = res.data;
      res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/attribute-filter?attribute=shell_length__length&caliber__type=${ammoType}`
      );
      filters.shell_length__length = res.data;
      res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/casings`
      );
      filters.casings = res.data;
      res = await axios.get(
        `https://ammo-guide-backend.eba-iqqvh63i.us-east-1.elasticbeanstalk.com/api/products/conditions`
      );
      filters.conditions = res.data;
      setFilters({ ...filters });
      console.log(filters);
    };

    if (ammoType) {
      getData();
      getFilter();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ammoType, activePage]);

  return (
    <div className="browseComponents">
      <div className="browseComponents__title">Browse Components</div>
      <div className="browseComponents__container">
        <div className="browseComponents__filter">
          <div className="browseComponents__filter-title">Filters</div>
          {filters && (
            <form>
              <label>
                Ammo type
                <select onChange={handleAmmoChange} defaultValue={ammo}>
                  <option value="Handgun Ammunition">Handgun</option>
                  <option value="Shotgun">Shotgun</option>
                  <option value="Rifle Ammunition">Rifle</option>
                  <option value="Rimfire Ammmunition">Rimfire</option>
                </select>
              </label>
              <label>
                Brand
                <select
                  onChange={(e) => {
                    selectedFilters.brand__iexact = e.target.value;
                    setSelectedFilters(selectedFilters);
                  }}
                >
                  <option value="">(any)</option>
                  {filters.brand &&
                    filters.brand.map(
                      (e) =>
                        e.brand !== null && (
                          <option value={e.brand}>{e.brand}</option>
                        )
                    )}
                </select>
              </label>
              {ammoType !== "Shotgun" && (
                <>
                  <label>
                    Caliber
                    <select
                      onChange={(e) => {
                        selectedFilters.caliber__name__iexact = e.target.value;
                        setSelectedFilters(selectedFilters);
                      }}
                    >
                      <option value="">(any)</option>
                      {filters.caliber__name &&
                        filters.caliber__name.map(
                          (e) =>
                            e.caliber__name !== null && (
                              <option value={e.caliber__name}>
                                {e.caliber__name}
                              </option>
                            )
                        )}
                    </select>
                  </label>
                  <label>
                    Grain
                    <select
                      onChange={(e) => {
                        selectedFilters.grain = e.target.value;
                        setSelectedFilters(selectedFilters);
                      }}
                    >
                      <option value="">(any)</option>
                      {filters.grain &&
                        filters.grain.map(
                          (e) =>
                            e.grain !== null && (
                              <option value={e.grain}>{e.grain}</option>
                            )
                        )}
                    </select>
                  </label>
                </>
              )}
              {ammoType === "Shotgun" && (
                <>
                  <label>
                    Gauge / Bore
                    <select>
                      <option value="">(any)</option>
                      {filters.caliber &&
                        filters.caliber.map(
                          (e) =>
                            e.caliber !== null && (
                              <option value={e.caliber}>{e.caliber}</option>
                            )
                        )}
                    </select>
                  </label>
                  <label>
                    Shot Type / Size
                    <select>
                      <option value="">(any)</option>
                      {filters.shot_size &&
                        filters.shot_size.map(
                          (e) =>
                            e.shot_size !== null && (
                              <option value={e.shot_size}>{e.shot_size}</option>
                            )
                        )}
                    </select>
                  </label>
                  <label>
                    Shell Length
                    <select
                      onChange={(e) => {
                        selectedFilters.shell_length__length__iexact =
                          e.target.value;
                        setSelectedFilters(selectedFilters);
                      }}
                    >
                      <option value="">(any)</option>
                      {filters.shell_length__length &&
                        filters.shell_length__length.map(
                          (e) =>
                            e.shell_length__length !== null && (
                              <option value={e.shell_length__length}>
                                {e.shell_length__length}
                              </option>
                            )
                        )}
                    </select>
                  </label>
                </>
              )}

              <label>
                Casing
                <select
                  onChange={(e) => {
                    selectedFilters.casing__type__iexact = e.target.value;
                    setSelectedFilters(selectedFilters);
                  }}
                >
                  <option value="">(any)</option>
                  {filters.casings &&
                    filters.casings.map(
                      (e) =>
                        e.type !== null && (
                          <option value={e.type}>{e.type}</option>
                        )
                    )}
                </select>
              </label>
              <label>
                Condition
                <select
                  onChange={(e) => {
                    selectedFilters.condition__name__iexact = e.target.value;
                    setSelectedFilters(selectedFilters);
                  }}
                >
                  <option value="">(any)</option>
                  {filters.conditions &&
                    filters.conditions.map(
                      (e) =>
                        e.name !== null && (
                          <option value={e.name}>{e.name}</option>
                        )
                    )}
                </select>
              </label>
              <label>
                Match these words in search
                <input type="text" />
              </label>
            </form>
          )}
          <button
            onClick={handleSubmit}
            className="browseComponents__filter-btn"
          >
            Apply Filters
          </button>
        </div>
        <div className="browseComponents__content">
          {width >= 600 && (
            <>
              <div className="browseComponents__content-title">
                <span>Name</span>
                <span>Brand</span>
                <span>Caliber</span>
                <span>Purchase</span>
                <span>Condition</span>
                <span>Price</span>
                <span>Shipping</span>
                <span>Quantity</span>
                <span>Rounds</span>
                {/* <span>Slug</span> */}
              </div>
              <div className="browseComponents__content-container">
                {data &&
                  data.map((e, idx) => (
                    <div
                      className={`browseComponents__content-box ${
                        idx % 2 !== 0 && "odd"
                      }`}
                      onClick={() => navigate(`/product/${e.slug}`)}
                    >
                      <span>{e.name ? e.name : "-"}</span>
                      <span>{e.brand ? e.brand : "-"}</span>
                      <span>{e.caliber ? e.caliber : "-"}</span>
                      <span>{e.purchase_limits ? e.purchase_limits : "-"}</span>
                      <span>{e.condition ? e.condition : "-"}</span>
                      <span>{e.price ? `$${e.price}` : "-"}</span>
                      <span>{e.shipping ? e.shipping : "-"}</span>
                      <span>{e.quantity ? e.quantity : "-"}</span>
                      <span>{e.price_per_round ? e.price_per_round : "-"}</span>
                      {/* <span>{e.slug ? e.slug : "-"}</span> */}
                    </div>
                  ))}
                {totalCount && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={15}
                    totalItemsCount={totalCount}
                    itemClass="page-item"
                    linkClass="page-link"
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </>
          )}
          {width < 600 && (
            <>
              {data &&
                data.map((e, idx) => (
                  <div
                    onClick={() => navigate(`/product/${e.slug}`)}
                    className="browseComponents__product-container"
                  >
                    <section>
                      <span style={{ marginRight: "auto" }}>
                        {e.name ? e.name : "-"}
                      </span>
                      <span>{e.price ? `$${e.price}` : "-"}</span>
                    </section>
                    <div>
                      <p>
                        Brand <span>{e.brand ? e.brand : "-"}</span>
                      </p>
                      <p>
                        Caliber <span>{e.caliber ? e.caliber : "-"}</span>
                      </p>
                      <p>
                        Quantity <span>{e.quantity ? e.quantity : "-"}</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Condition <span>{e.condition ? e.condition : "-"}</span>
                      </p>
                      <p>
                        Shipping <span>{e.shipping ? e.shipping : "-"}</span>
                      </p>
                      <p>
                        Rounds{" "}
                        <span>
                          {e.price_per_round ? e.price_per_round : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              {totalCount && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={15}
                  totalItemsCount={totalCount}
                  itemClass="page-item"
                  linkClass="page-link"
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrowseComponents;
